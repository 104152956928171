.vue-dropzone,
.vue-dropzone * {
    box-sizing: border-box;
}

.vue-dropzone {
    position: relative;
    border: 2px dashed #7dc84b;
}
.vue-dropzone.dz-clickable {
    cursor: pointer;
}
.vue-dropzone.dz-clickable:before {
    content: 'Загрузка файлов';
}
.vue-dropzone .dz-preview {
    position: relative;
    display: inline-block;
    width: 120px;
    margin: 0.5em;
}

.vue-dropzone .dz-preview .dz-progress {
    display: block;
    height: 15px;
    border: 1px solid #aaa;
}

.vue-dropzone .dz-preview .dz-progress .dz-upload {
    display: block;
    height: 100%;
    width: 0;
    background: green;
}

.vue-dropzone .dz-error-message {
    color: red;
    display: none;
}

.vue-dropzone .dz-preview.dz-error .dz-error-message,
.vue-dropzone .dz-preview.dz-error .dz-error-mark {
    display: block;
}

.vue-dropzone .dz-preview.dz-success .dz-success-mark {
    display: block;
}

.vue-dropzone .dz-preview .dz-error-mark,
.vue-dropzone .dz-preview .dz-success-mark {
    position: absolute;
    display: none;
    top: 30px;
    width: 54px;
    height: 58px;
    left: 50%;
    margin-left: -27px;
}
