@use "sass:meta";
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@include meta.load-css('variables');

// Bootstrap
//@import '~bootstrap/scss/bootstrap';
@include meta.load-css("~bootstrap-icons/font/bootstrap-icons");
//@import '../css/app.css';
@import '../css/vue.nice.select.css';
@import '../css/nice-select.css';
@import '../css/chosen.css';
@import '../css/vue.dropzone.css';
@import '../js/plugins/dropzone/basic.min.css';
@import '../js/plugins/dropzone/dropzone.min.css';
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "~select2/dist/css/select2.min.css";
@import "~admin-lte/dist/css/adminlte.min.css";
@import "variables";

.cursor-help {
    cursor: help;
}
.user-panel {
    overflow: initial;
}
.user-panel img {
    height: revert-layer;
    width: revert-layer;
}
.image-responsive {
    max-width: 100%;
}
.dz-success-mark, .dz-error-mark {
    display: none;
}
.dz-error.dz-complete .dz-error-mark {
    display: inline-block;
}
.dz-success.dz-complete .dz-success-mark {
    display: inline-block;
}
.photosource-image {
    background: url(../img/transback.png);
}
.csv-list > *:after {
    content: ', ';
}
.csv-list > *:last-child:after {
    content: '';
}

.card-header>.card-tools {
    float: right;
    margin-right: -0.925rem;
    margin-top: -0.325rem;
    margin-bottom: -0.625rem;
}

/**
 * Переключение светлой/темной темы
 */
.theme-switch {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 50px;
}
.theme-switch input {
    display: none;
}
.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 400ms;
}
.slider.round {
    border-radius: 34px;
}
input:checked + .slider {
    background-color: #131862;
}
.slider::before,
.slider::after {
    bottom: 4px;
    content: "";
    height: 16px;
    left: 4px;
    position: absolute;
    transition: 400ms;
    width: 16px;
}
.slider::after {
	height: 16px;
	width: 16px;
	bottom: 6px;
    left: -1px;
}
.slider::before {
	background-color: #fff;
}
.slider::after {
	background-color: transparent;
}
.slider.round::before,
.slider.round::after {
    border-radius: 50%;
}
input:not(:checked) + .slider::before {
	background-color: #FFDE00;
	border-radius: 50%;
	box-shadow:
		0 0 0 2px #FFDE0080,
		0 0 0 4px #FFDE0020,
		0 0 0 10px #FFDE0000,
		0 0 4px 10px #FFDE0010;
	animation:
		sunrise 2s infinite linear forwards,
		rays 2s 2s infinite linear;
}
input:checked + .slider::before,
input:checked + .slider::after {
    transform: translateX(26px);
}
input:checked + .slider::after {
    background-color: #131862cc;
    border-radius: 50%;
    transform: translateX(27px);
    width: 16px;
    height: 16px;
    bottom: 6px;
    left: -1px;
}

@keyframes sunrise {
	0% {
		box-shadow: none;
	}
}

@keyframes rays {
	0% {
		box-shadow:
            0 0 0 0 #FFDE0080,
            0 0 0 2px #FFDE0080,
            0 0 0 4px #FFDE0020,
            0 0 4px 10px #FFDE0010;
	}
	100% {
		box-shadow:
            0 0 0 2px #FFDE0080,
            0 0 0 4px #FFDE0020,
            0 0 0 10px #FFDE0000,
            0 0 4px 10px #FFDE0010;
	}
}
/**
 * END Переключение светлой/темной темы
 */

 .dark-mode {
     background-color: #343a40 !important;
     color: #fff !important;

     .custom-file-label, .custom-file-label::after, .form-control:not(.form-control-navbar):not(.form-control-sidebar):not(.is-invalid):not(:focus) {
         border-color: #6c757d !important;
     }
     .custom-control-label::before, .custom-file-label, .custom-file-label::after, .custom-select, .form-control:not(.form-control-navbar):not(.form-control-sidebar), .input-group-text {
         background-color: #343a40 !important;
         color: #fff !important;
     }
     .chosen-container-multi .chosen-choices,
     .chosen-container-single .chosen-single,
     .chosen-container .chosen-drop {
         background-color: #343a40 !important;
         color: #fff !important;
     }
     .border {
         border-color: #555 !important;
     }

     .chosen-container-multi .chosen-drop .result-selected {
         color: #666;
     }

     .chosen-container .chosen-results li.active-result {
         color: #fff;
     }

     .chosen-container-single .chosen-single span {
         color: #fff;
     }
     .table-success, .table-success > td, .table-success > th {
         background-color: #42684b;
     }
 }


@media (min-width: 1200px) {
    .modal-xxl {
        max-width: calc(100% - 40px);
    }
}
@media (min-width: 992px) {
    .modal-xxl {
        max-width: calc(100% - 40px);
    }
}
.modal-backdrop {
    background-color: #00000059;
}

.react-btn {
    padding: 15px;
    opacity: 0.3;
    svg {
        filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, 0.7));
    }
    &.like {
        color: $green;
    }
    &.dislike {
        color: $red;
    }
    &:hover {
        opacity: 1;
    }
    &.active {
        opacity: 1;
    }
}

.debugbar-toggle {
    opacity: .8;
    &:hover {
        opacity: 1;
    }
}
